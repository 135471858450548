export default {
    name: "Status",
    props: {
        status: {
            type: Object,
            default: () => ({ text: "Not Set", color: "red" }),
        },
    },
    data() {
        return {
            showDropdown: false,
            selected: "",
            toggle: true,
        };
    },
    methods: {
        setStatus(list) {
            this.selected = list;
            this.$emit("updateStatus", list);
        },
    },
};
