export default [
    {
        align: "center",
        text: "JOB ID",
    },
    {
        align: "center",
        text: "POSITION",
    },
    {
        align: "center",
        text: "POSTED BY",
    },
    {
        align: "center",
        text: "DATE",
    },
    {
        align: "center",
        text: "APPLICATIONS",
    },
    {
        align: "center",
        text: "STATUS",
    },
    {
        align: "center",
        text: "empty",
    },
];
