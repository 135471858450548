var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__wrapper"},[(_vm.loading)?_c('div',{staticClass:"skeleton"},[_c('div',{staticClass:"skeleton-head"}),_vm._l((8),function(k){return _c('div',{key:k,staticClass:"skeleton-row"},_vm._l((4),function(i){return _c('div',{key:i,staticClass:"skeleton-item"})}),0)})],2):_c('table',{staticClass:"table",class:{
            'table--secondary': _vm.type === 'secondary',
            'table--sm': _vm.size === 'sm',
        }},[_c('table-header',{attrs:{"sticky-header":_vm.stickyHeader,"headers":_vm.tableHeaders,"expandable":_vm.expandable},on:{"sortTable":function($event){_vm.sortBy = $event}}}),_c('tbody',[(!_vm.tableData.length)?_c('tr',[_c('td',{staticClass:"table__row-item table__row-item--empty",attrs:{"colspan":_vm.tableHeaders.length}},[_vm._v(" No data available ")])]):_vm._l((_vm.sortedData),function(item,index){return [_c('tr',{key:item[_vm.keyValue] || item.id || index,staticClass:"table__row",class:{
                        'table__row--expandable': _vm.expandable,
                        expanded: _vm.isExpanded(index),
                        'table__row--muted': _vm.muted && index === 0,
                    },on:{"click":function($event){return _vm.toggleExpanded(index)}}},[(_vm.expandable)?_c('td',{staticClass:"table__icon"},[_c('svg',{attrs:{"width":"14","height":"14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.01 9.678L7.683 7 5.011 4.322l.822-.822 3.5 3.5-3.5 3.5-.822-.822z","fill":"#A7A7A7"}})])]):_vm._e(),_vm._t("table-row",null,{"item":item,"index":index})],2),_c('transition',{key:((item.id || index) + "+'animation'"),attrs:{"name":"slide-fade"}},[(_vm.isExpanded(index))?_c('tr',{staticClass:"table__row-details"},[_c('td'),_vm._t("table-details",null,{"item":item})],2):_vm._e()])]})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }