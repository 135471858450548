<template>
  <div>
    <div class="flex flex__spaced mb-3">
      <h1 class="fs-18 fw-500 text__dark-gre use-poppins">Jobs</h1>
      <Button
        @click="showModal = !showModal"
        theme="blue w-15 p-1 flex flex__item-center flex__spaced"
      >
        <div class="flex">
          <img src="@/assets/icons/add-icon-white.svg" alt="add-icon" />
        </div>
        <span class="text__white fs-14 fw-600">Post New Job</span>
      </Button>
    </div>

    <div class="flex flex__gap-5 mb-3">
      <SearchInput
        v-model="jobSearchValue"
        placeholder="Search Job Listing"
        :delay="1000"
        type="secondary"
      />
      <div
        class="job__filter-btn cursor-pointer flex flex__spaced flex__item-center"
      >
        <div class="flex">
          <img
            src="@/assets/icons/filter-icon-blue.svg"
            alt="filter-icon-blue"
          />
        </div>
      </div>
    </div>

    <div>
      <Table :tableData="jobsData" :tableHeaders="Headers">
        <template v-slot:table-row="{ item: job, index }">
          <td class="table__row-item center">{{ job.job_id }}</td>
          <td class="table__row-item center">{{ job.position }}</td>
          <td class="table__row-item center">{{ job.posted_by }}</td>
          <td class="table__row-item center">{{ job.date }}</td>
          <td class="table__row-item center">
            {{ job.applications }}
          </td>
          <td class="table__row-item center">
            <Status :status="status(job.status)" />
          </td>
          <td
            class="table__row-item center no-overflow"
            :class="`z-${jobsData.length - index}`"
          >
            <div class="u-relative">
              <Dropdown
                :options="moreOptions"
                :child="true"
                @selected="handleSelect($event, index)"
              >
                <template v-slot:actions="{ handleBlur, handleToggle }">
                  <svg
                    width="24"
                    height="6"
                    viewBox="0 0 24 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="cursor-pointer"
                    @click="handleToggle"
                    tabindex="-1"
                    @blur="handleBlur"
                  >
                    <path
                      d="M2.66683 2.99984H2.68016M12.0002 2.99984H12.0135M21.3335 2.99984H21.3468M4.00016 2.99984C4.00016 3.35346 3.85969 3.6926 3.60964 3.94265C3.35959 4.19269 3.02045 4.33317 2.66683 4.33317C2.31321 4.33317 1.97407 4.19269 1.72402 3.94265C1.47397 3.6926 1.3335 3.35346 1.3335 2.99984C1.3335 2.64622 1.47397 2.30708 1.72402 2.05703C1.97407 1.80698 2.31321 1.6665 2.66683 1.6665C3.02045 1.6665 3.35959 1.80698 3.60964 2.05703C3.85969 2.30708 4.00016 2.64622 4.00016 2.99984ZM13.3335 2.99984C13.3335 3.35346 13.193 3.6926 12.943 3.94265C12.6929 4.19269 12.3538 4.33317 12.0002 4.33317C11.6465 4.33317 11.3074 4.19269 11.0574 3.94265C10.8073 3.6926 10.6668 3.35346 10.6668 2.99984C10.6668 2.64622 10.8073 2.30708 11.0574 2.05703C11.3074 1.80698 11.6465 1.6665 12.0002 1.6665C12.3538 1.6665 12.6929 1.80698 12.943 2.05703C13.193 2.30708 13.3335 2.64622 13.3335 2.99984ZM22.6668 2.99984C22.6668 3.35346 22.5264 3.6926 22.2763 3.94265C22.0263 4.19269 21.6871 4.33317 21.3335 4.33317C20.9799 4.33317 20.6407 4.19269 20.3907 3.94265C20.1406 3.6926 20.0002 3.35346 20.0002 2.99984C20.0002 2.64622 20.1406 2.30708 20.3907 2.05703C20.6407 1.80698 20.9799 1.6665 21.3335 1.6665C21.6871 1.6665 22.0263 1.80698 22.2763 2.05703C22.5264 2.30708 22.6668 2.64622 22.6668 2.99984Z"
                      stroke="#374151"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </Dropdown>
            </div>
          </td>
        </template>
      </Table>

      <div class="flex flex__spaced m-flex-end mt-2 flex__item-center">
        <div><span class="text__grey fs-18 fw-600">Showing</span></div>
        <Pagination
          @paginate="handlePagination"
          :currentPage="currentPage"
          :perPage="10"
          :total="120"
        />
      </div>
    </div>

    <Modal
      :active="showModal"
      size="wide"
      position="top"
      :showCloseIcon="false"
    >
      <CreateJob @close-modal="showModal = !showModal" />
    </Modal>
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput";
import Table from "@/components/Table";
import Status from "@/components/Status";
import Modal from "@/components/Modal";
import Dropdown from "@/components/Dropdown";
import Headers from "./components/tableHeader";
import CreateJob from "./components/CreateJob";
import Pagination from "@/components/Pagination";
import adminService from "../../../../services/api/adminService";

export default {
  name: "AdminJobs",
  components: {
    SearchInput,
    Table,
    Status,
    Modal,
    CreateJob,
    Pagination,
    Dropdown,
    allJobs: [],
    loading: false
  },
  data() {
    return {
      showDropdown: [],
      moreOptions: [
        { name: "Edit", label: "Edit" },
        { name: "Delete", label: "Delete" }
      ],
      jobSearchValue: "",
      Headers,
      currentPage: 1,
      showModal: false,
      jobsData: [
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "inactive"
        },
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "active"
        },
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "inactive"
        },
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "active"
        },
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "active"
        },
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "active"
        },
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "active"
        },
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "inactive"
        },
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "active"
        },
        {
          job_id: "123122",
          position: "Developer",
          posted_by: "Smith Greene",
          date: "12/03/2022",
          applications: "120",
          status: "inactive"
        }
      ]
    };
  },
  methods: {
    getAllJobs() {
      this.loading = true;
      adminService
        .getAllJobs()
        .then((res) => {
          this.jobsData = res.data.data.rows;
        })
        .catch((e) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    status(text) {
      switch (text) {
        case "inactive":
          return { text, color: "red" };
        case "active":
          return { text, color: "green" };
        default:
          return { text: "Not set", color: "red" };
      }
    },
    handleSelect(option, index) {},
    handlePagination(page) {
      this.currentPage = page;
    }
  },
  mounted() {
    this.getAllJobs();
  }
};
</script>

<style scoped lang="scss">
.job__filter-btn {
  padding: 0 13px;
  border: 1px solid #a1a4b1;
  border-radius: 4px;
  height: 48px;
}
</style>
